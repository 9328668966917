import { Component, OnInit, Input } from "@angular/core";
import { HelpdeskService } from "src/app/services/helpdesk.service";
import { Observable, of, Subject, Subscription } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  switchMap,
  catchError,
  tap
} from "rxjs/operators";
import { Dealer } from "src/app/interfaces/dealer";
import { ApiResponse } from "src/app/interfaces/api-response";
import { User } from "src/app/interfaces/user";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmDialogComponent } from "../../modal-components/confirm-dialog/confirm-dialog.component";
import { UserOptionsComponent } from "../user-options/user-options.component";
import { AccountService } from "src/app/services/account.service";
import { ApplicationService } from "src/app/services/application.service";
import { ActivatedRoute, Router } from "@angular/router";
import { BasketService } from "src/app/services/basket.service";
import { CompanyGroup } from "src/app/interfaces/company-group";
import * as appGlobal from "src/app/globals";
import { FormGroup, FormBuilder, FormControl, Validators } from "@angular/forms";
import { AlertService } from "src/app/services/alert.service";
import { TranslateService } from "@ngx-translate/core";
import { HelperService } from "src/app/services/helper.service";

@Component({
  selector: "app-search-dealers",
  templateUrl: "./search-dealers.component.html",
  styleUrls: ["./search-dealers.component.scss"]
})
export class SearchDealersComponent implements OnInit {
  @Input() redirectUrl = "";
  selectedIndex: number;
  searching: boolean;
  searchFailed: boolean;
  foccused = new Subject<string>();
  dealers: Dealer[];
  selectedDealer: Dealer;
  lastSearch: string;
  users: User[];
  last_activity: string;
  keyWord: string;
  loading: boolean;
  formGroup: FormGroup;
  usersCall: Subscription = null;
  constructor(
    private helpdeskService: HelpdeskService,
    private modalService: NgbModal,
    private accountService: AccountService,
    private applicationService: ApplicationService,
    private basketService: BasketService,
    private router: Router,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private translateService: TranslateService,
    private helperService: HelperService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {

     this.formGroup = this.formBuilder.group({
      searchText: new FormControl( this.helpdeskService.getLastSearch(this.route.snapshot.paramMap.get('dealerid')), [Validators.required, Validators.minLength(3)])
     });

    if (this.helpdeskService.getLastSearch(this.route.snapshot.paramMap.get('dealerid'))) {
      this.helpdeskService.searchDealers(this.helpdeskService.getLastSearch(this.route.snapshot.paramMap.get('dealerid'))).subscribe(data => {
        this.dealers = data;
      });
    }
  }

  // Select dealer and show the corresponding users
  selectDealer(dealer: Dealer) {
    this.selectedDealer = dealer;

    // abort previous call
    if (this.usersCall !== null) {
      this.usersCall.unsubscribe();
    }

    this.applicationService.showLoader(true);
    this.usersCall = this.helpdeskService
      .getUsers(dealer.dealer_code)
      .subscribe((apiResponse: ApiResponse) => {
        this.applicationService.hideLoader();
        if (this.helperService.checkResponse(apiResponse)) {
          this.users = apiResponse.result["users"];
          this.last_activity = apiResponse.result["last_activity"];
        }
      });
  }

  showOptions(user: User) {
    const modal = this.modalService.open(ConfirmDialogComponent, {
      container: "#modalContainer"
    });

    modal.componentInstance.title = user.username;
    user.dealerIsOktaAccount = this.selectedDealer.okta;
    modal.componentInstance.setContent(UserOptionsComponent, "user", user);
    modal.dismissed.subscribe(() => {
      this.selectDealer(this.selectedDealer);
    })
  }

  // Select the user
  selectUser(user: User) {
    this.applicationService.showLoader(true);
    const bearerToken = localStorage.getItem("bearerToken");
    const refreshToken = localStorage.getItem("refreshToken");
    const preferedDefaultCompanyGroupCode = localStorage.getItem("defaultCompanyGroup") !== null ? localStorage.getItem("defaultCompanyGroup") : this.applicationService.getSelectCompanyGroupCode();
    this.helpdeskService.logInAsUser(user).then(() => {
      this.accountService
        .getAccountSettings()
        .subscribe((apiResponse: ApiResponse) => {
          this.applicationService.hideLoader();
          if (this.helperService.checkResponse(apiResponse)) {
            this.applicationService.saveAccountSettings(apiResponse.result);
            this.accountService.saveDealerInformation(this.selectedDealer);

            // Save the account code
            this.applicationService.saveSetting(
              "accountCode",
              this.selectedDealer.dealer_code
            );


            localStorage.removeItem("companyGroups");
            localStorage.removeItem("cgc");
            sessionStorage.clear();
            appGlobal.clearSettings();
            // Get available company groups
            this.accountService
              .getAvailableCompanyGroups()
              .subscribe((response: ApiResponse) => {
                if (response.success) {
                  let companyGroup = "ANL";
                  this.applicationService.saveCompanyGroups(response.result);
                  const companyGroups: CompanyGroup[] = response.result;

                  // Check if dealer has access to prefered default company 
                  const preferedDefaultCompanyGroup = (companyGroups.find((c: CompanyGroup) => {
                    return c.company_group_code === preferedDefaultCompanyGroupCode
                  }))

                  if (preferedDefaultCompanyGroup) {
                    companyGroup = preferedDefaultCompanyGroup.company_group_code;
                    this.applicationService.saveSelectedCompanyGroupCode(companyGroup);
                  } else {
                    if (companyGroups && companyGroups.length) {
                      companyGroup = companyGroups[0].company_group_code;
                    }
                  }

                  if (!this.redirectUrl || preferedDefaultCompanyGroup) {
                    this.redirectUrl = `/${companyGroup}/home`;
                  }

                  window.location.href = this.redirectUrl;
                  localStorage.setItem("refresh", "1");
                } else {
                  this.alertService.showErrorNotification(
                    `${this.translateService.instant(
                      "ERROR"
                    )}: Dealer niet geactiveerd!`
                  );
                  localStorage.setItem("bearerToken", bearerToken);
                  localStorage.setItem("refreshToken", refreshToken);
                }
              });
          }
        });
    });
  }

  onPaste(event: ClipboardEvent) {
    const el = document.getElementById("framenumberInput") as HTMLInputElement;
    const clipboardData = event.clipboardData || window["clipboardData"];
    this.formGroup.get("searchText").setValue(clipboardData.getData("text"));
    this.searchDealers(event);
  }

  searchDealers(e) {
    this.search();
    e.preventDefault();
    return false;
  }

  search() {
    this.users = [];
    const keyword = String(this.formGroup.get("searchText").value).replace(/(\r\n|\n|\r)/gm, "").trim();

    this.formGroup.get("searchText").setValue(keyword);
    if (this.formGroup.valid) {
      this.searching = true;
      setTimeout(()=> {
        this.searching = false;
      }, 1000);
      this.helpdeskService.searchDealers(keyword).subscribe((dealers: Dealer[]) => {
        this.searching = false;
        if (dealers && dealers.length) {
          this.helpdeskService.saveLastSearch(keyword);
          this.searchFailed = false;
          this.dealers = dealers;
        } else {
          this.dealers = [];
        }
      })
    }
  }
}
